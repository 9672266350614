import './App.css';
import Navbar from './components/main/navbar'; // Uvezi Navbar komponentu
import Rightbar from './components/main/rightbar'; // Uvezi Navbar komponentu
import Hero from './components/sections/hero/hero'; // Uvezi Navbar komponentu
import Novosti from './components/sections/novosti/novosti'; // Uvezi Navbar komponentu
import Smestaj from './components/sections/smestaj/smestaj'; // Uvezi Navbar komponentu
import Restoran from './components/sections/restoran/restoran'; // Uvezi Navbar komponentu
import Igraonica from './components/sections/igraonica/igraonica'; // Uvezi Navbar komponentu
import Story from './components/sections/story/story'; // Uvezi Navbar komponentu
import Footer from './components/main/footer'; // Uvezi Navbar komponentu
import { LanguageProvider } from './LanguageContext'; // Uvezi LanguageProvider

function App() {
  return (
    <LanguageProvider>
      <div className="App">  
          <Navbar />
          <Rightbar />
          <Hero />
          <Story />
          <Smestaj />
          <Restoran />
          <Igraonica />
          <Novosti />
          <Footer />
      </div>
    </LanguageProvider>
  );
}

export default App;
