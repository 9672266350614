import React, { useState, useEffect, useRef } from 'react';
import './Restoran.scss'; // Import SCSS styles
import { useLanguage } from '../../../LanguageContext'; // Uvezi useLanguage

const Restoran = () => {
  const { language, setLanguage } = useLanguage(); // Uzmi jezik iz konteksta

  // Images for the slideshow
  const images = [
    "https://imgur.com/TYyXvk3.jpg",
    "https://imgur.com/3thfa2J.jpg",
    "https://imgur.com/KHupVWO.jpg",
    "https://imgur.com/XqP0zQg.jpg",
    "https://imgur.com/8FL0sGB.jpg",
    "https://imgur.com/qZa2bwV.jpg",
    "https://imgur.com/xSjxWwp.jpg",
    "https://imgur.com/YX74Q05.jpg",
    "https://imgur.com/BiW7dTI.jpg",
    "https://imgur.com/K5GTMiz.jpg",
    "https://imgur.com/fJKcbEH.jpg",
    "https://imgur.com/4mVFnxB.jpg"
  ];

  // State to keep track of the current image index and fade effect
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true); // State to control the fade effect

  // Language texts
  const texts = {
    en: {
      title: "Welcome to Our Restaurant",
      description1: "We believe that dining is not just about food; it’s about creating cherished memories with loved ones. Our family-friendly atmosphere welcomes everyone, from young children to grandparents, making it the perfect spot for gatherings, celebrations, or a casual meal together.",
      description2: "Indulge in a diverse menu that caters to all tastes, featuring fresh ingredients and traditional recipes that will remind you of home. Whether you’re craving a hearty family feast or a light snack, our dishes are crafted with love and care to ensure everyone leaves with a smile.",
      description3: "Our dedicated team is committed to providing exceptional service, making you feel right at home from the moment you step through our doors. Enjoy our cozy setting, filled with laughter and joy, where every meal is a celebration of togetherness.",
      description4: "Join us for a delightful dining experience where families bond over delicious food, and friendships are nurtured. We can't wait to welcome you to our table!"
    },
    sr: {
      title: "Dobrodošli u naš restoran",
      description1: "Verujemo da objedovanje nije samo o hrani; to je stvaranje dragocenih uspomena s voljenima. Naša porodična atmosfera dočekuje sve, od male dece do baka i deka, što je čini savršenim mestom za okupljanja, proslave ili opušten obrok zajedno.",
      description2: "Uživajte u raznolikom jelovniku koji zadovoljava sve ukuse, sa svežim sastojcima i tradicionalnim receptima koji će vas podsetiti na dom. Bez obzira da li želite bogat obrok za porodicu ili lagani užinu, jela su napravljena s ljubavlju i pažnjom kako bi svako otišao s osmehom.",
      description3: "Naš posvećen tim je posvećen pružanju izvanredne usluge, čineći da se od trenutka kada zakoračite kroz naša vrata osećate kao kod kuće. Uživajte u našoj prijatnoj atmosferi, ispunjenoj smehom i radošću, gde je svaki obrok proslava zajedništva.",
      description4: "Pridružite nam se za divno iskustvo objedovanja gde se porodice okupljaju oko ukusne hrane i gde se prijateljstva neguju. Radujemo se što ćemo vas dočekati za našim stolom!"
    },
    ru: {
      title: "Добро пожаловать в наш ресторан",
      description1: "Мы верим, что еда — это не только о пище; это создание дорогих воспоминаний с близкими. Наша дружелюбная атмосфера приветствует всех, от маленьких детей до бабушек и дедушек, что делает ее идеальным местом для встреч, празднований или неформального ужина вместе.",
      description2: "Наслаждайтесь разнообразным меню, которое удовлетворяет все вкусы, с использованием свежих ингредиентов и традиционных рецептов, которые напомнят вам о доме. Независимо от того, хотите ли вы hearty семейный пир или легкий перекус, наши блюда готовятся с любовью и заботой, чтобы каждый ушел с улыбкой.",
      description3: "Наша преданная команда стремится предоставлять исключительный сервис, чтобы вы чувствовали себя как дома с момента, когда вы входите в наши двери. Наслаждайтесь нашим уютным окружением, наполненным смехом и радостью, где каждый прием пищи — это праздник единства.",
      description4: "Присоединяйтесь к нам для приятного обеденного опыта, где семьи сближаются благодаря вкусной пище и дружеским отношениям. Мы с нетерпением ждем возможности приветствовать вас за нашим столом!"
    }
  };

  // Function to update the image index every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Start fading out

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true); // Start fading in
      }, 1000); // Delay image change until the fade-out completes
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div className="restaurant">
      <div className="restaurant-card">
        <h1 className="restaurant-title">{texts[language].title}</h1>
        <p className="restaurant-description">{texts[language].description1}</p>
        <p className="restaurant-description">{texts[language].description2}</p>
        <p className="restaurant-description">{texts[language].description3}</p>
        <p className="restaurant-description">{texts[language].description4}</p>
      </div>

      <div className="restaurant-right">
        {/* Display the current image from the slideshow */}
        <img
          className={`restaurant-image ${fade ? 'fade-in' : 'fade-out'}`} // Apply fade class based on state
          src={images[currentImageIndex]}
          alt="Restaurant Slideshow"
        />
      </div>
    </div>
  );
};

export default Restoran;
