import React from 'react';
import './Igraonica.scss';
import { useLanguage } from '../../../LanguageContext'; // Uvezi useLanguage

const Igraonica = () => {
  const { language } = useLanguage(); // Uzmi jezik iz konteksta

  // Language texts
  const texts = {
    en: {
      title: "Welcome to the Playroom!",
      description: "At our playroom, we believe that every child deserves a safe and joyful environment to explore and play. Our facility is designed with child safety in mind, featuring soft play areas, age-appropriate toys, and constant supervision by our trained staff. Enjoy a wide variety of fun activities that stimulate creativity and social skills, ensuring that your child has an unforgettable experience while making new friends!",
    },
    sr: {
      title: "Dobrodošli u Igraonicu!",
      description: "U našoj igraonici verujemo da svako dete zaslužuje sigurno i veselo okruženje za istraživanje i igru. Naš prostor je dizajniran s pažnjom na sigurnost dece, s mekim igraonicama, igračkama prilagođenim uzrastu i stalnim nadzorom naših obučenih radnika. Uživajte u širokom spektru zabavnih aktivnosti koje stimulišu kreativnost i socijalne veštine, osiguravajući da vaše dete ima nezaboravno iskustvo dok stiče nove prijatelje!",
    },
    ru: {
      title: "Добро пожаловать в Игровую комнату!",
      description: "В нашей игровой комнате мы верим, что каждый ребенок заслуживает безопасной и радостной среды для исследований и игр. Наше заведение разработано с учетом безопасности детей, включая мягкие игровые зоны, игрушки, подходящие для их возраста, и постоянный контроль со стороны нашего обученного персонала. Наслаждайтесь широким спектром увлекательных мероприятий, которые развивают креативность и социальные навыки, обеспечивая незабываемый опыт для вашего ребенка в кругу новых друзей!",
    }
  };

  return (
    <div className="igraonica">
      <div className="left-column">
        <div className="image-row">
          <img src="https://imgur.com/YavIKPH.png" alt="Image 1" />
          <img src="https://imgur.com/DGxXeYC.png" alt="Image 2" />
        </div>
        <div className="image-row">
          <img src="https://imgur.com/OXVZVKB.png" alt="Image 3" />
          <img src="https://imgur.com/5mD8AV2.png" alt="Image 4" />
        </div>
      </div>
      <div className="igraonica-card">
        <h1 className="card-title">{texts[language].title}</h1>
        <p className="card-description">
          {texts[language].description}
        </p>
      </div>
      <div className="right-column">
        <div className="image-row">
          <img src="https://imgur.com/BnS0Zjp.png" alt="Image 5" />
          <img src="https://imgur.com/MoflxQU.png" alt="Image 6" />
        </div>
        <div className="image-row">
          <img src="https://imgur.com/MMoOeme.png" alt="Image 7" />
          <img src="https://imgur.com/DGxXeYC.png" alt="Image 8" />
        </div>
      </div>
    </div>
  );
};

export default Igraonica;
