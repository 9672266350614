import React from 'react';
import './Story.scss'; // Import SCSS styles
import { useLanguage } from '../../../LanguageContext'; // Import useLanguage context

const Story = () => {
  const { language } = useLanguage(); // Get current language from context

  // Define texts for all languages
  const texts = {
    sr: {
      title: "Naša Priča",
      paragraphs: [
        "Savršeno mesto za porodicu, pogodno za decu,",
        "gde možete uživati u najboljem mogućem kvalitetu odmora.",
        "Porodica na okupu, sve na jednom mestu,",
        "restoran, igraonica, apartman, spa centar i još mnogo toga.",
        "Dođite i otkrijte kako izgledaju trenuci sreće i zajedništva,",
        "uz brojne sadržaje koji će obogatiti vaš odmor."
      ],
    },
    en: {
      title: "Our Story",
      paragraphs: [
        "A perfect place for families, suitable for children,",
        "where you can enjoy the highest quality of relaxation.",
        "Family together, everything in one place — restaurant,",
        "playroom, apartment, spa center, and much more.",
        "Come and discover how moments of happiness and togetherness look,",
        "with numerous facilities that will enrich your vacation."
      ],
    },
    ru: {
      title: "Наша История",
      paragraphs: [
        "Идеальное место для семей, подходящее для детей,",
        "где вы можете насладиться самым высоким качеством отдыха.",
        "Семья вместе, всё на одном месте — ресторан,",
        "игровая комната, апартаменты, спа-центр и многое другое.",
        "Приходите и узнайте, как выглядят моменты счастья и единства,",
        "с многочисленными удобствами, которые обогатят ваш отпуск."
      ],
    },
  };

  return (
    <div className="story">
      <div className="story-left">
        <img
          src="https://imgur.com/6jYFcUi.jpg"
          alt="About Me"
          className="story-image"
        />
      </div>
      <div id="story" className="story-right">
        <h2 className="story-title">{texts[language].title}</h2>
        {texts[language].paragraphs.map((paragraph, index) => (
          <p key={index} className="story-paragraph">
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};

export default Story;
