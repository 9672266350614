import React, { useState } from 'react';
import './Smestaj.scss'; // Import SCSS styles

const Smestaj = ({ language }) => {
  const [modalImage, setModalImage] = useState(null); // State for storing the clicked image

  const texts = {
    sr: {
      cards: [
        { id: 1, text: "Kartica 1", imageUrl: "https://imgur.com/8F4xpgj.png" },
        { id: 2, text: "Kartica 2", imageUrl: "https://imgur.com/mIoXQGu.png" },
        { id: 3, text: "Kartica 3", imageUrl: "https://imgur.com/RvkuGnb.png" },
        { id: 4, text: "Kartica 4", imageUrl: "https://imgur.com/o1dm1pR.png" },
        { id: 5, text: "SMEŠTAJ", imageUrl: "", isPlain: true },
        { id: 6, text: "Kartica 6", imageUrl: "https://imgur.com/KjQIFdO.png" },
        { id: 7, text: "Kartica 7", imageUrl: "https://imgur.com/ceFxLLp.png" },
        { id: 8, text: "Kartica 8", imageUrl: "https://imgur.com/NMCV5VP.png" },
        { id: 9, text: "Kartica 9", imageUrl: "https://imgur.com/CoAusYb.png" },
      ],
      additionalText: "Lepo Udobno Čisto",
    },
    en: {
      cards: [
        { id: 1, text: "Card 1", imageUrl: "https://imgur.com/8F4xpgj.png" },
        { id: 2, text: "Card 2", imageUrl: "https://imgur.com/mIoXQGu.png" },
        { id: 3, text: "Card 3", imageUrl: "https://imgur.com/RvkuGnb.png" },
        { id: 4, text: "Card 4", imageUrl: "https://imgur.com/o1dm1pR.png" },
        { id: 5, text: "ACCOMMODATION", imageUrl: "", isPlain: true },
        { id: 6, text: "Card 6", imageUrl: "https://imgur.com/KjQIFdO.png" },
        { id: 7, text: "Card 7", imageUrl: "https://imgur.com/ceFxLLp.png" },
        { id: 8, text: "Card 8", imageUrl: "https://imgur.com/NMCV5VP.png" },
        { id: 9, text: "Card 9", imageUrl: "https://imgur.com/CoAusYb.png" },
      ],
      additionalText: "Nice Comfortable Clean",
    },
    ru: {
      cards: [
        { id: 1, text: "Carte 1", imageUrl: "https://imgur.com/8F4xpgj.png" },
        { id: 2, text: "Carte 2", imageUrl: "https://imgur.com/mIoXQGu.png" },
        { id: 3, text: "Carte 3", imageUrl: "https://imgur.com/RvkuGnb.png" },
        { id: 4, text: "Carte 4", imageUrl: "https://imgur.com/o1dm1pR.png" },
        { id: 5, text: "HÉBERGEMENT", imageUrl: "", isPlain: true },
        { id: 6, text: "Carte 6", imageUrl: "https://imgur.com/KjQIFdO.png" },
        { id: 7, text: "Carte 7", imageUrl: "https://imgur.com/ceFxLLp.png" },
        { id: 8, text: "Carte 8", imageUrl: "https://imgur.com/NMCV5VP.png" },
        { id: 9, text: "Carte 9", imageUrl: "https://imgur.com/CoAusYb.png" },
      ],
      additionalText: "Beau Confortable Propre",
    },
  };

  // Fallback to 'en' if language is not defined
  const currentTexts = texts[language] || texts.sr;

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl); // Set the clicked image in state
  };

  const closeModal = () => {
    setModalImage(null); // Clear the image from state to close the modal
  };

  return (
    <div id="smestaj" className="smestaj">
      {currentTexts.cards.map((card) => (
        <div key={card.id} className={`smestaj-card ${card.isPlain ? 'plain-card' : ''}`}>
          {card.isPlain ? (
            <div className="text-only-card">
              <h3 className="card-title">{card.text}</h3>
              <br />
              <p className="card-description">{currentTexts.additionalText}</p>
            </div>
          ) : (
            <div className="smestaj-image" onClick={() => handleImageClick(card.imageUrl)}>
              <img src={card.imageUrl} alt={card.text} />
              <div className="overlay"></div>
              <div className="smestaj-text">
                <p>{card.text}</p>
              </div>
              <div className="bottom-left-text">Additional Text</div>
            </div>
          )}
        </div>
      ))}

      {modalImage && (
        <div className="image-modal" onClick={closeModal}>
          <span className="close-button">&times;</span>
          <img className="modal-content" src={modalImage} alt="Full View" />
        </div>
      )}
    </div>
  );
};

export default Smestaj;
