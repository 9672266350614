import React from 'react';
import './Novosti.scss'; // Import SCSS styles
import { useLanguage } from '../../../LanguageContext'; // Import useLanguage

const Novosti = () => {
  const { language } = useLanguage(); // Get language from context

  // Language texts
  const texts = {
    en: {
      title: "News",
      offerDetails: [
        "Only during October and November! Treat yourself and relax your body and soul with a stay in the Zlatibor Apart Complex Binis!",
        "We have prepared for you:",
        "DETOX PACKAGE FOR 2 PERSONS",
        "- 3 nights in 4-star apartments",
        "- Half board (breakfast and dinner with detox menu)",
        "- Use of the SPA center (hydromassage bath, sauna, steam room, tepiderium)",
        "- Skin detox in the steam room with raspberry peeling",
        "Package price: 4,850 dinars per person per day."
      ],
    },
    sr: {
      title: "Novosti",
      offerDetails: [
        "Samo tokom oktobra i novembra! Obradujte sebe i odmorite svoje telo i dušu boravkom na Zlatiboru u Apartkompleksu Binis!",
        "Za vas smo pripremili:",
        "DETOX PAKET ZA 2 OSOBE",
        "- 3 noćenja u apartmanima sa 4 zvezdice",
        "- Polupansion (doručak i večera po detox meniju)",
        "- Korišćenje SPA centra (hidromasažna kada, sauna, parno kupatilo, tepiderium)",
        "- Detox kože u parnom kupatilu sa pilingom od maline",
        "Cena paketa: 4.850 dinara po osobi po danu."
      ],
    },
    ru: {
      title: "Новости",
      offerDetails: [
        "Только в октябре и ноябре! Побалуйте себя и расслабьте тело и душу, остановившись в комплексе Apart Binis на Златиборе!",
        "Мы подготовили для вас:",
        "ДЕТОКС-ПАКЕТ ДЛЯ 2 ХОЗЯЕВ",
        "- 3 ночи в 4-звездочных апартаментах",
        "- Полупансион (завтрак и ужин по детокс-меню)",
        "- Использование SPA-центра (гидромассажная ванна, сауна, парная, тепидариум)",
        "- Детокс кожи в парной с малиновым пилингом",
        "Цена пакета: 4.850 динаров с человека в день."
      ],
    },
  };

  return (
    <div className="novosti">
      <div className="left-column">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12807.155767328268!2d19.6944539!3d43.7299676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47582faa9aa646f7%3A0x78f0e50fd479cdb5!2sAPARTMANI%20BINIS!5e0!3m2!1sen!2srs!4v1696265201641!5m2!1sen!2srs"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>
      <div className="middle-column">
        <h2 className="news-title">{texts[language].title}</h2>
        {texts[language].offerDetails.map((paragraph, index) => (
          <p key={index} className="offer-details">
            {paragraph}
          </p>
        ))}
      </div>
      <div className="right-column">
        <h2 className="info-title">Kontakt Informacije</h2>
        <p><strong>Adresa:</strong> Erska 29, 31315 Zlatibor</p>
        <p><strong>Email:</strong> binis.apartmani.zlatibor@gmail.com</p>
        <p><strong>Recepcija:</strong> +381 62 223 980</p>
        <p><strong>Restoran:</strong> +381 60 400 97 90</p>
      </div>
    </div>
  );
};

export default Novosti;
