import React, { useState, useEffect, useRef } from 'react';
import './Navbar.scss'; // SCSS stilovi
import { useLanguage } from '../../LanguageContext'; // Uvezi useLanguage

const Navbar = () => {
  const { language, setLanguage } = useLanguage(); // Uzmi jezik iz konteksta
  const translations = {
    en: {
      contact: "Contact",
      ourStory: "Our Story",
      accommodation: "Accommodation",
      restaurant: "Restaurant",
      playground: "Playground",
      complex: "BINIS COMPLEX"
    },
    sr: {
      contact: "Kontakt",
      ourStory: "Naša Priča",
      accommodation: "Smeštaj",
      restaurant: "Restoran",
      playground: "Igralište",
      complex: "BINIS KOMPLEKS"
    },
    ru: {
      contact: "Контакт",
      ourStory: "Наша История",
      accommodation: "Жилье",
      restaurant: "Ресторан",
      playground: "Игровая площадка",
      complex: "БИНИС КОМПЛЕКС"
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <select className="language-select" onChange={handleLanguageChange} value={language}>
          <option value="en">EN</option>
          <option value="sr">SR</option>
          <option value="ru">RU</option>
        </select>
        <button className="contact-btn">{translations[language].contact}</button>
        <button className="contact-btn">{translations[language].ourStory}</button>
      </div>
      <div className="navbar-center">
        <img src="/logo512.png" alt="Logo" className="navbar-logo" />
        <p className="navbar-text">{translations[language].complex}</p>
      </div>
      <div className="navbar-right">
        <button className="accommodation-btn">{translations[language].accommodation}</button>
        <button className="restaurant-btn">{translations[language].restaurant}</button>
        <button className="playground-btn">{translations[language].playground}</button>
      </div>
      <div className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      {isOpen && (
        <div className="mobile-menu" ref={mobileMenuRef}>
          <button className="contact-btn" onClick={toggleMenu}>{translations[language].contact}</button>
          <button className="contact-btn" onClick={toggleMenu}>{translations[language].ourStory}</button>
          <button className="accommodation-btn" onClick={toggleMenu}>{translations[language].accommodation}</button>
          <button className="restaurant-btn" onClick={toggleMenu}>{translations[language].restaurant}</button>
          <button className="playground-btn" onClick={toggleMenu}>{translations[language].playground}</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
