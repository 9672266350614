import React, { useState, useEffect } from 'react';
import './Hero.scss';
import { useLanguage } from '../../../LanguageContext'; // Uvezi useLanguage

const images = [
  'https://imgur.com/ENcLPmc.png',
  'https://imgur.com/wwC0AiN.png',
  'https://imgur.com/Nb75dIT.png',
];

const texts = {
  sr: [
    'PRAVO MESTO ZA TEBE I PORODICU',
    'MESTO ZA ODMOR I UZIVANJE',
    'SVE NA JEDNOM MESTU',
  ],
  en: [
    'THE RIGHT PLACE FOR YOU AND YOUR FAMILY',
    'A PLACE FOR REST AND ENJOYMENT',
    'EVERYTHING IN ONE PLACE',
  ],
  ru: [
    'ПРАВИЛЬНОЕ МЕСТО ДЛЯ ВАС И ВАШЕЙ СЕМЬИ',
    'МЕСТО ДЛЯ ОТДЫХА И УДОВОЛЬСТВИЯ',
    'ВСЁ В ОДНОМ МЕСТЕ',
  ],
};

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const { language } = useLanguage(); // Uzmi jezik iz konteksta

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeIn(false); // Start fading out the current image and text

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFadeIn(true); // Start fading in the new image and text
      }, 1000); // 1 second fade-out time
    }, 5000); // Change image every 6 seconds (5 seconds visible + 1 second transition)

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="hero" className="hero">
      <div className={`overlay ${fadeIn ? 'fade-in' : 'fade-out'}`}></div>
      <img
        src={images[currentIndex]}
        alt={`Slika ${currentIndex + 1}`}
        className={`hero-image ${fadeIn ? 'fade-in' : 'fade-out'}`}
      />
      <div className={`hero-text ${fadeIn ? 'fade-in' : 'fade-out'}`}>
        <h1>{texts[language][currentIndex]}</h1>
      </div>
    </div>
  );
};

export default Hero;
