import React from 'react';
import './Footer.scss'; // Import SCSS styles
import { FaFacebook, FaInstagram, FaTiktok, FaMapMarkerAlt, FaBook } from 'react-icons/fa'; // Import icons

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="social-icons">
          <a href="https://www.facebook.com/p/Apart-kompleks-BINIS-61558442823571/?locale=nn_NO" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/apartkompleksbinis/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
        </div>
        <p>All Rights Reserved © 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
