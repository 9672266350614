import React from 'react';
import './Rightbar.scss'; // SCSS stilovi

const RightBar = () => {
  return (
    <div className="rightbar">
      <div className="dots-container">
        <a href="#hero" className="dot"></a>
        <a href="#story" className="dot"></a>
        <a href="#smestaj   " className="dot"></a>
        <a href="#story" className="dot"></a>
        <a href="#story" className="dot"></a>
      </div>
    </div>
  );
};

export default RightBar;
